<template>
	<div class="custom-table">
		<div class="row table-content">
			<div class="titulo-tabela col-12" v-bind:class="{ 'col-md-4': search }">
				<h2 v-if="title">{{title}}</h2>
			</div>			
			<div class="col-md-4 offset-md-4 col-12 d-flex justify-end align-center btn-opcoes-headers">
				<v-btn class="secondary-button" @click="activeMenuColunas">
					Opções
				</v-btn>
				<transition name="fade">
					<div v-if="menu_colunas_" class="sub-menu-headers">
						<span class="span-titulo-submenu">
							Visibilidade das Colunas
						</span>
						<ul v-for="(option, i) in colums" :key="option.value">
							<li>
								<v-btn class="btn-opcao-coluna" @click="changeColums(i)">
									<v-icon v-if="option.display" dark>mdi-check</v-icon>
									<v-icon v-else dark>mdi-cancel</v-icon>
									{{option.text}}
								</v-btn>
							</li>
						</ul>
					</div>
				</transition>
			</div>
			<div v-if="search" class="col-md-6 col-12 d-flex justify-end align-center div-busca">
				<v-text-field 
					type="text"
					v-model="busca" 
					placeholder="Buscar por nome..."
					@change="hendleBusca"
				/>
				<div class="div-icon">
					<v-icon>fas fa-search</v-icon>
				</div>
			</div>
			<div class="col-12 tabela_">
				<v-data-table 
					:headers="computedHeaders" 
					:items="dados" 
					:items-per-page="perPage" 
					class="elevation-1"
					:options.sync="options"
					hide-default-footer
					:loading="loading" 
					loading-text="Processando"
				>
					<template v-for="header in colums" v-slot:[`item.${header.value}`]="item">
						<div :class="header.value" :key="header.value">
							<slot :name="header.value" v-bind="item">{{item.value}}</slot>
						</div>
					</template>
					
				</v-data-table>
			</div>
			<div v-if="pagination" class="col-12 justify-end d-flex pt-2">
				<v-pagination circle v-model="currentPage" :length="rows" @input="handlePagination"/>
			</div>
		</div>
	</div>
</template>

<script>
	import store from '@/store'

	export default {
		props: ["action", "getter", "headers", "search", "title", "icone", "pagination", "filters"],
		name: "CustomTable",
		data() {
			return {
				loading: true,
				dados: [],
				options: {},
				busca: '',
				order: '',
				order_by: '',
				total: 0,
				currentPage: 1,
				perPage:  this.filters.perPage || 10, 
				menu_colunas_: false,
				colums: [],
			}
		},
		methods:{
			async init(){
				
				await this.changeLoading(true)
				await store.dispatch(
					this.action, 
					this.updateFilters()
				)
				this.changeLoading(false)
			},
			handlePagination(value) {
				this.currentPage = value
				this.init()
			},
			async sortingChanged() {
				this.order = await this.options.sortDesc[0] ? 'desc' : 'asc'
				this.order_by = await this.options.sortBy[0]
				this.init();
			},
			hendleBusca() {
				this.currentPage = 1
				this.init()
			},
			changeLoading(value) {
				this.loading = value
			},
			updateFilters(){
				return {
					adicionais: this.filters,
					offset: (this.perPage * (this.currentPage - 1)),
					limit:  (this.total != 1 ? this.total : this.perPage),
					order_column: this.order_by,
					order_dir: this.order,
					search: this.busca,
					currentPage: this.currentPage
				}
			},
			activeMenuColunas(){
				this.menu_colunas_ = !this.menu_colunas_
			},
			async setHeaders(){
				let temp_header = []
				await this.headers.forEach(element => {
					temp_header.push({
						...element,
						display: true
					})
				});
				this.colums = temp_header
			},
			async changeColums(item){
				this.colums[item].display = !this.colums[item].display
			}
		},
		watch: {
			updateTable () {
				this.dados = this.getter.dados
				this.total = this.getter.total
			},
			options: {
				handler () {
					this.sortingChanged()
				},
				deep: true,
			},
		},
		mounted(){
			this.init()
			this.setHeaders()
		},
		computed: {
			rows() {
				return Math.ceil(this.total/this.perPage)
			},
			updateTable () {
				return this.getter
			},
			computedHeaders(){
				return this.colums.filter(item => item.display)
			}
		},
	}
</script>

<style lang="scss">
	.primary-button{
		background-color: #FE8204;
		color: #fff;
		font-weight: 500;
		svg{
			margin-right: 5px;
		}
	}
	.custom-table{
		flex: 1;
		padding: 12px;
		background-color: #404040;
		margin-bottom: 30px;
		padding-top: 0;
		.table-content{
			flex: 1;
			.titulo-tabela{
				display: flex;
    			align-items: center;
				text-transform: uppercase;
				font-family: 'Poppins';
				h2{
					font-style: normal;
					font-weight: 500;
					font-size: 25px;
					line-height: 28px;
					color: #fff;
					border-bottom: 4px solid #FE8204;
					flex: none;
					order: 0;
					flex-grow: 0;
					margin: 10px 0px;
					width: max-content;
				}
			}
			table{
				font-weight: 500;
			}
			.btn-opcoes-headers{
				position: relative;
				font-family: 'Poppins';
				.secondary-button{
					background-color: #e94e37;
					border-color: #e94e37;
					color: #fff;
				}
				.btn-only-icon{
					background-color: $primarycolor!important;
				}
				.sub-menu-headers{
					min-width: 310px;
					position: absolute;
					top: 80%;
					right: 0;
					background-color: #fff;
					padding: 10px;
					box-shadow: 10px 10px 24px -17px rgba(0,0,0,0.75);
					flex-wrap: wrap;
					display: flex;
					flex-direction: column;
					z-index: 1;
					.span-titulo-submenu{
						text-align: center;
					}
					ul{
						list-style: none;
						padding: 0;
						li{
							margin-bottom: 5px;
							.btn-opcao-coluna{
								box-shadow: none!important;
								width: 100%;
								text-align: left!important;
								padding: 0;
								height: inherit!important;
								span{
									text-align: left;
									padding: 5px;
									justify-content: flex-start;
									text-transform: capitalize;
								}
							}
						}
					}
				}
			}
			.div-busca{
				padding: 0 15px;
				position: relative;
				.v-input{
					margin: 0;
					padding: 0;					
					.v-input__slot{
						background: #F9F9F9;
						border: 1px solid #C8C8C8;
						box-sizing: border-box;
						border-radius: 30px;
						padding: 5px 60px 5px 15px;
						margin: 0;
						box-shadow: 5px 5px 20px -1px rgba(0,0,0,0.65);
						-webkit-box-shadow: 5px 5px 20px -1px rgba(0,0,0,0.65); 
						&:before, &:after{
							display: none;							
						}
					}
					.v-text-field__details{
						display: none;
					}
				}
				.div-icon{
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #FE8204;
					border: 1px solid #FE8204;
					border-radius: 0 30px 30px 0 ;
					width: 60px;
					right: 15px;
					top: 0;
					height: 100%;
					cursor: pointer;
					svg, i{
						padding: 0 15px;
						width: 45px;
						margin: 0;
						z-index: 1;
						color: #fff;
					}
				}
			}
			.tabela_{
				.v-data-table__wrapper{
					border-radius: 5px;
					box-shadow: 5px 5px 20px -1px rgba(0,0,0,0.65);
					-webkit-box-shadow: 5px 5px 20px -1px rgba(0,0,0,0.65); 
				}
				table{
					font-family: 'Poppins';
					color: #404040;
					font-weight: 500;
					thead{
						background-color: #F2F2F2;
						tr{
							th{
								color: #404040 !important;
								font-size: 13px;
							}
						}
					}
					tr{
						transition: all ease .3s;
						&:nth-child(even){							
							background-color: #F2F2F2 !important;
						}
						td{
							.status{
								img{
									margin-left: 10px;
									width: 35px;
								}
							}
							.acoes{
								display: flex;
								button{
									padding: 0 10px !important;
									span{
										font-size: 12px;
										svg, i{
											margin-right: 5px;
										}
									}
									&:nth-child(1){
										margin-right: 10px;
									}
								}
							}
							.config_menu, .config_nome{
								display: flex;
								align-items: center;
								img{
									width: 25px;
									margin-left: 25px;
									cursor: pointer;
								}
							}
						}
						&:hover{
							background-color: #e2e2e2 !important;
						}
					}
				}
			}
		}
	}
	.sem-dados{
		background-color: #fff;
		border-radius: 10px;
		padding: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		h4{
			font-size: 16px;
		}
	}
</style>